import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { DBStores } from '../../models/indexedDb/indexedDb.store.model';
import { TourReport } from '../../models/tourReport.model';
import { User } from '../../models/user.model';
import { Form } from '../../models/form.model';
import { OfflineDataDateUpdate } from '../../models/offlineDataDateUpdate.model';
import { FormResponseOfflineData } from '../../models/tourReportForm/formResponseOfflineData';
import { Alert } from '../../models/alert.model';
import { TourReportAction } from '../../models/tourReportAction.model';
import { ActionFormResponse } from '../../models/actionFormResponse.model';
import { ActionFormReview } from '../../models/actionFormReview.model';
import { ProcessTourUsers } from '../../models/processTourUsers.model';

@Injectable({
  providedIn: 'root',
})
export class AppDatabaseService extends Dexie {
  form!: Dexie.Table<Form, number>;
  tourReport!: Dexie.Table<TourReport, number>;
  user!: Dexie.Table<User, string>;
  lastSyncronizationDate!: Dexie.Table<OfflineDataDateUpdate, number>;
  formResponseOffline!: Dexie.Table<FormResponseOfflineData, number>;
  alerts!: Dexie.Table<Alert, number>;
  tourReportAction!: Dexie.Table<TourReportAction, number>;
  actionFormResponse!: Dexie.Table<ActionFormResponse, number>;
  actionFormReview!: Dexie.Table<ActionFormReview, number>;
  processTourUser!: Dexie.Table<ProcessTourUsers, [number, number, string]>;

  versionNumber: number = 24;

  constructor() {
    super('index-db-app');
    
    this.version(this.versionNumber).stores(this.setTablesSchema());
    
    this.form = this.table<Form, number>(DBStores.form.tableName);
    this.tourReport = this.table<TourReport, number>(DBStores.tourReport.tableName);
    this.user = this.table<User, string>(DBStores.user.tableName);
    this.lastSyncronizationDate = this.table<OfflineDataDateUpdate, number>(DBStores.OfflineDataDateUpdates.tableName);
    this.formResponseOffline = this.table<FormResponseOfflineData, number>(DBStores.formResponseOffline.tableName);
    this.alerts = this.table<Alert, number>(DBStores.alert.tableName);
    this.tourReportAction = this.table<TourReportAction, number>(DBStores.tourReportAction.tableName);
    this.actionFormResponse = this.table<ActionFormResponse, number>(DBStores.actionFormResponse.tableName);
    this.actionFormReview = this.table<ActionFormReview, number>(DBStores.actionFormReview.tableName);
    this.processTourUser = this.table<ProcessTourUsers, [number, number, string]>(DBStores.processTourUser.tableName);
  }

  setTablesSchema() {
    return Object.entries(DBStores).reduce((tables, [key, value]) => {
        tables[value.tableName] = value.columns;
      return tables;
    }, {} as Record<string, string>);
  }

  async clearDB() {
    this.close();
    await this.delete();
    await this.open();
  }
}