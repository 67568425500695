import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { HttpFormService } from './core/services/http/http-form.service';
import { DexieCrudService } from './core/services/indexedDb/dexie-crud.service';
import { AppDatabaseService } from './core/services/indexedDb/app-database.service';
import { OfflineDataDateUpdate } from './core/models/offlineDataDateUpdate.model';
@Injectable({
    providedIn: 'root'
})
export class OfflineDataResolver implements Resolve<any> {

    constructor(
        private readonly httpFormsService: HttpFormService, 
        private readonly dbService: AppDatabaseService
    ) {}

    resolve(): Observable<any> {
        let lastSyncronizationDateService: DexieCrudService<OfflineDataDateUpdate, number> = new DexieCrudService<OfflineDataDateUpdate, number>(this.dbService.lastSyncronizationDate);
        lastSyncronizationDateService.getAll().then((lastSyncronizationDate: OfflineDataDateUpdate[]) => {
            let lastSyncronizationDateObject: Date = lastSyncronizationDate[0]?.syncronizationDate;
            if (lastSyncronizationDate.length === 0 || (lastSyncronizationDateObject?.getDate() !== new Date().getDate() 
                && lastSyncronizationDateObject?.getMonth() !== new Date().getMonth() 
                && lastSyncronizationDateObject?.getFullYear() !== new Date().getFullYear())) {
                
                    let formsSubscription: Subscription = this.httpFormsService
                        .getFormList(0, 1000).subscribe((forms) => formsSubscription.unsubscribe());
                    
                    lastSyncronizationDateService.AddAsync(new OfflineDataDateUpdate());
            }
        });

        return of(1);
    }
}