import { animate, style, transition, trigger } from "@angular/animations";

export const dropdownMenuAnimation = trigger('dropdownMenuAnimation', [
  transition(':enter', [
        style({
            opacity: 0,
            transform: 'translateY(-40px)'
        }),
        animate(250)
      ]),
    transition(':leave', [
        animate(250, style({
            opacity: 0,
            transform: 'translateY(-40px)'
        }))
    ])
]);