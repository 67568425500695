import { Injectable } from "@angular/core";
import { AppConstants } from "src/app/consts/app-constants";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {

    public isApplicationInsightsEnabled(): boolean {
        return Boolean(environment[AppConstants.ApplicationInsightsConfigurationName].isEnabled);
    }

    public isGrafanaEnabled(): boolean {
        return Boolean(environment[AppConstants.GrafanaConfigurationName].isEnabled);
    }
}
