import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { User } from '../../models/user.model';
import * as bcrypt from 'bcryptjs';
import { NotifyToasterService } from '../toaster/notify-toaster.service';
import { AppDatabaseService } from '../indexedDb/app-database.service';

@Injectable({
  providedIn: 'root'
})
export class UserCrudService {

  dbSet: Dexie.Table<User, string>;

  constructor(private database: AppDatabaseService, private toasterService: NotifyToasterService) {
    this.dbSet = this.database.user;
  }

  getByEmail(email: string) : Promise<User> {
    return this.dbSet.where('email').equals(email).first();
  }

  getById(id: string) {
    return this.dbSet.get(id);
  }

  async AddAsync(item: User): Promise<void> {
    item.password = this.hashPassword(item.password);
    item.isAuthenticated = true;
    await this.dbSet.add(item).then(async (result) => {
      this.toasterService.showSuccess('Usuario creado');
      await this.setUserAsAuthenticated(item);
    }).catch((error) => {
      this.toasterService.showError('Error al crear usuario');
    });;
  }

  async AddOrEditAsync(item: User): Promise<void> {
    await this.dbSet.put(item);
  }

  async UpdateAsync(id: string, changes: any): Promise<void> {
    await this.dbSet.update(id, changes);
  }

  async RemoveAsync(id: string): Promise<void> {
    await this.dbSet.delete(id);
  }

  async loginUser(username: string, password: string): Promise<User> {
    const user = await this.dbSet.where('username').equals(username).first();
    if (user && this.verifyPassword(password, user.password)) {
      await this.setUserAsAuthenticated(user);
      return user;
    }
  }

  private hashPassword(password: string) {
    const salt = bcrypt.genSaltSync(10);
    const hashedPassword = bcrypt.hashSync(password, salt);
    return hashedPassword;
  }

  private verifyPassword(password: string, hashedPassword: string) {
    return bcrypt.compare(password, hashedPassword);
  }

  getCurrentUser(): Promise<User> {
    return this.dbSet.filter(user => user.isAuthenticated === true).first();
  }

  async setUserAsAuthenticated(user: User) {
    await this.logoutAllUsers();
    await this.dbSet.update(user.id, { isAuthenticated: true });
  }

  async logoutAllUsers() {
    await this.dbSet.toCollection().modify({ isAuthenticated: false });
  }
}
