import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private readonly http: HttpClient) { }

  generateApiRoute(route: string) {
    return environment.apis.default.url + `/api/app/${route}`;
  }

  get<T>(url: string, spinnerName: string = '') {
    return this.http.get<T>(this.generateApiRoute(url), {
      headers: {
        SpinnerName: spinnerName
      }
    });
  }

  post<T>(url: string, body: any, spinnerName: string = '') {
    return this.http.post<T>(this.generateApiRoute(url), body, {
      headers: {
        SpinnerName: spinnerName
      }
    });
  }

  put<T>(url: string, body: any, spinnerName: string = '') {
    return this.http.put<T>(this.generateApiRoute(url), body, {
      headers: {
        SpinnerName: spinnerName
      }
    });
  }

  delete(url: string, spinnerName: string = '') {
    return this.http.delete(this.generateApiRoute(url), {
      headers: {
        SpinnerName: spinnerName
      }
    });
  }
}
