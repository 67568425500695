export class TourReportFieldFile {
    id: number;
    name: string;
    contentType: string;
    tourReportFieldId: number;
    fileContent: string;

    constructor() {
        this.name = '';
        this.contentType = '';
        this.tourReportFieldId = 0;
        this.fileContent = '';
        this.id = 0;
    }
} 