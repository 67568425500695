import { Injectable } from '@angular/core';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';
import { UserCrudService } from '../user/user-crud-service.service';
import { NotifyToasterService } from '../toaster/notify-toaster.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineAuthService {
  private currentUser: User;

  constructor(private router: Router, private userCrudService: UserCrudService, private toasterService: NotifyToasterService) { }

  public async loginUser(username: string, password: string) {
    let user = await this.userCrudService.loginUser(username, password);

    if (user) {
      this.setAuthenticatedUser(user);
      this.router.navigate(['/home']);
    } else {
      this.toasterService.showError('Usuario o contraseña incorrectos');
    }
  }

  public getCurrentUser(): Promise<User> {
    return this.userCrudService.getCurrentUser();
  }

  public setAuthenticatedUser(user: User) {
    this.currentUser = user;
  }

  public async authenticateUserFromOnlineLogin(user: User) {
    let userFromDatabase = await this.userCrudService.getByEmail(user.email);

    if (userFromDatabase) {
      this.setAuthenticatedUser(userFromDatabase);
      await this.userCrudService.setUserAsAuthenticated(user);
    }
  }

  public getRoles(): string[] {
    return this.currentUser.roles;
  }

  public async logout() {
    this.currentUser = null;
    await this.userCrudService.logoutAllUsers();
  }

  navigateToLogin() {
    this.router.navigate(['/loginOffline']);
  }
}
