import { Injectable } from '@angular/core';
import { TOUR_REPORT_STATUS_NEW, TOUR_REPORT_STATUS_IN_PROCESS, TOUR_REPORT_STATUS_CLOSED, TOUR_REPORT_STATUS_PENDING_ACTIONS } from '../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class TourReportStatusService {

  private newTourReportStateId: number;
  private inProcessTourReportStateId: number;
  private pendingActionsTourReportStateId: number;
  private finishedTourReportStateId: number;

  private readonly newTourReportStateText = TOUR_REPORT_STATUS_NEW;
  private readonly inProcessTourStatusText = TOUR_REPORT_STATUS_IN_PROCESS;
  private readonly finishedTourStatusText = TOUR_REPORT_STATUS_CLOSED;
  private readonly pendingActionsTourStatusText = TOUR_REPORT_STATUS_PENDING_ACTIONS;

  constructor() { }

  setFinishedTourReportStateId(response: number) {
    this.finishedTourReportStateId = response;
  }
  setPendingActionsTourReportStateId(response: number) {
    this.pendingActionsTourReportStateId = response;
  }
  setInProcessTourReportStateId(response: number) {
    this.inProcessTourReportStateId = response;
  }
  setNewTourReportStateId(response: number) {
    this.newTourReportStateId = response;
  } 

  getFinishedTourReportStateId() {
    return this.finishedTourReportStateId;
  }

  getPendingActionsTourReportStateId() {
    return this.pendingActionsTourReportStateId;
  }

  getInProcessTourReportStateId() {
    return this.inProcessTourReportStateId;
  }

  getNewTourReportStateId() {
    return this.newTourReportStateId;
  }

  areStatusLoaded(): boolean {
    return this.newTourReportStateId !== undefined && this.inProcessTourReportStateId !== undefined && this.pendingActionsTourReportStateId !== undefined && this.finishedTourReportStateId !== undefined;
  }

  getTourStatusText(tourStatusId: number): string {
    if (this.newTourReportStateId === tourStatusId) {
      return this.newTourReportStateText;
    } else if (this.pendingActionsTourReportStateId === tourStatusId) {
      return this.pendingActionsTourStatusText;
    } else if (this.inProcessTourReportStateId === tourStatusId) {
      return this.inProcessTourStatusText;
    } else if (this.finishedTourReportStateId === tourStatusId) {
      return this.finishedTourStatusText;
    } else {
      return '';
    }
  }
}