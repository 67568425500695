export const SPINNER_NAME = 'main_spinner';
export const SPINNER_NAME_PROCESS_SELECTOR = 'process_selector_spinner';
export const SPINNER_NAME_TOUR_TYPES = 'tour_types_spinner';
export const SPINNER_NAME_TOUR_TYPE_IN_FORMS = 'forms_tourType_spinner';
export const SPINNER_NAME_TOUR_REPORTS = 'tour_reports_spinner';
export const SPINNER_NAME_PROCESS_TYPE_IN_FORMS = 'forms_ProcessType_spinner';
export const SPINNER_NAME_IN_FORMS = 'forms_allForm_spinner';
export const SPINNER_NAME_FORMS = 'forms_spinner';
export const SPINNER_NAME_HEADER = 'SpinnerName';
export const SPINNER_NAME_ACTIONS_LIST = 'actions_list_spinner';
export const SPINNER_NAME_EDIT_ACTION = 'edit_action_spinner';
export const SPINNER_NAME_TOUR_TYPE_SELECTOR = 'tour_type_selector_spinner';
export const SPINNER_NAME_BLOCK_SELECTOR = 'block_selector_spinner';
export const SPINNER_NAME_TOUR_REPORTS_CREATE = 'tour_reports_create_spinner';
export const SPINNER_NAME_SAVE_TOUR_REPORT_ACTION = 'save_tour_report_action_spinner';
export const SPINNER_NAME_TOUR_REPORTS_LIST = 'tour_reports_list_spinner';
export const SPINNER_NAME_USER_TOUR_SELECTOR = 'tour_reports_edit_spinner';
export const SPINNER_NAME_ESTABLISHMENT_SELECTOR = 'establishment_selector_spinner';
export const SPINNER_NAME_TOUR_REPORT_DETAILS = 'tour_reports_details_list_spinner';
export const SPINNER_NAME_EXECUTED_ACTIONS_LIST = 'executed_actions_list_spinner';
export const SPINNER_NAME_EXECUTED_ACTION_DETAILS = 'executed_action_details_spinner';
export const SPINNER_NAME_REGION_SELECTOR = 'region_selector_spinner';
export const SPINNER_NAME_DASHBOARD_CLOSE_TIME_CARD = "tourCloseTimeSpinner";
export const SPINNER_NAME_DASHBOARD_EXECUTION_TIME_CARD = "tourExecutionTimeSpinner";

export const SPINNER_NAME_TOURS_DASHBOARD_IN_PROCESS = "tours_dashboard_in_process_spinner";
export const SPINNER_NAME_TOURS_DASHBOARD_PENDING_ACTIONS = "tours_dashboard_pending_actions_spinner";
export const SPINNER_NAME_TOURS_DASHBOARD_OUT_OF_TIME = "tours_dashboard_out_of_time_spinner";
export const SPINNER_NAME_TOURS_DASHBOARD_PROPORTION = "tours_dashboard_proportion_spinner";

export const SPINNER_NAME_ACTION_DASHBOARD_OUT_OF_TIME = "action_dashboard_out_of_time_spinner";
export const SPINNER_NAME_ACTION_DASHBOARD_PROPORTION = "action_dashboard_proportion_spinner";

export const SPINNER_NAME_TOURS_WITHOUT_ACTIONS_DASHBOARD = "tours_without_actions_dashboard_spinner";
export const SPINNER_NAME_TOURS_WITHOUT_ACTIONS_DASHBOARD_VARIANCE = "tours_without_actions_dashboard_variance_spinner";


export const SPINNER_NAME_PROCESS_LIST = 'process_list_spinner';

export const GET_USER_CONSTANT = 'currentUser';

export const MOBILE_VERSION_WIDTH = 768;

export const PROCESS_COLUMN_INDEX = 1;
export const ATTRIBUTE_COLUMN_INDEX = 2;
export const TOURTYPE_COLUMN_INDEX = 3;

export const TOUR_REPORT_STATUS_NEW = "Pendiente";
export const TOUR_REPORT_STATUS_IN_PROCESS = "En proceso";
export const TOUR_REPORT_STATUS_PENDING_ACTIONS = "Acciones pendientes";
export const TOUR_REPORT_STATUS_CLOSED = "Cerrada";


export const ACTION_STATUS_PENDING_NAME = 'Pendiente';
export const ACTION_STATUS_MADE_NAME = 'Realizada';
export const ACTION_STATUS_CLOSED_NAME = 'Cerrada';
export const ACTION_STATUS_NON_COMPLETED_NAME = 'Cerrada no realizada';

export const ALERT_TOUR_REPORT_TYPE = "TourReportNotificationDto";
export const ALERT_ACTION_TYPE = "ActionNotificationDto";

export const SUPERVISOR_ROLE_NAME = "supervisor";
export const PROCESS_LEADER_ROLE_NAME = "process_leader";
