<section class="flex md:h-full flex-col items-center overflow-y-scroll overflow-x-hidden !bg-pst-black-100 relative transition-all ease-linear z-50" 
    [ngClass]="{'md:w-[200px] h-full': this.isOpened, 'md:w-[80px] h-auto md:!h-full': !this.isOpened}">
    
    <header class="flex justify-center items-center h-[62px] w-full text-pst-blue-10 p-0 mb-2" [class]="{'!justify-between': this.isMobileVersion}">
        @if (this.isOpened) {
            <button mat-button color="primary" (click)="this.toggleSidebar()" class="justify-items-center text-white !w-full !h-full">
                <img class="!w-full !h-12" [src]="'assets/logo/logo-horizontal.svg'" alt="app-logo"/>
            </button>
        } @else {
            <button mat-button color="primary" (click)="this.toggleSidebar()" class="!p-0 justify-items-center text-white !w-full !h-full">
                <img class="!h-10 !w-10" [src]="'assets/logo/logo.svg'" alt="app-logo"/>
            </button>
        }

        @if (this.isMobileVersion && this.isOpened) {
            <button id="alert" class="flex items-center justify-end rounded-md hover:bg-pst-blue-100 transition-all duration-300 mr-2" (click)="this.openMenu()">
                @if (this.notificationsCount !== 0) {
                    <mat-icon  class="scale-75 custom-badge" aria-hidden="false" [matBadge]="this.notificationsCount" svgIcon="notification"></mat-icon>
                } @else {
                    <mat-icon class="scale-75" svgIcon="notification"></mat-icon>
                }
            </button>
        }
    </header>

    @if (this.isOpened || !this.isMobileVersion) {
        @for (section of sections; track $index) {
            @for (icon of section; track $index) {
                <section [routerLink]="!icon.openMenu ? icon.url : null" class="px-[0.75rem] py-[0.625rem] text-pst-blue-10 w-full cursor-pointer"
                    [ngClass]="{'!py-[0.2rem]' : this.isOpened, 'justify-items-center' : !this.isOpened}">
                    <button [id]="icon.openMenu ? 'alert' : $index.toString()" 
                            class="flex items-center justify-center rounded-md hover:bg-pst-blue-100 transition-all duration-300 p-[0.625rem]" 
                            [ngClass]="{'w-fit bg-pst-blue-100': this.checkIfUrlMatches(icon.url), 'w-full !justify-start py-[0.5rem] pl-[0.2rem]' : this.isOpened}"
                            (click)="icon.openMenu ? this.openMenu() : null"
                    >
                        @if (icon.openMenu && this.notificationsCount !== 0) {
                            <mat-icon class="!h-6 !w-6 custom-badge" aria-hidden="false" [matBadge]="this.notificationsCount" svgIcon="{{ icon.icon }}"></mat-icon>
                        } @else {
                            <mat-icon class="!h-6 !w-6" svgIcon="{{ icon.icon }}"></mat-icon>
                        }
                        @if (this.isOpened) {
                            <a @appear class="hover:!text-white ml-2 text-start" >{{ icon.title }}</a>
                        }
                    </button>
                </section>
                
                @if (this.isOpened) {
                    @for (item of icon.children; track $index) {
                        <section [routerLink]="item.url" class="pl-[32px] pr-2 py-[0.75rem] text-pst-blue-10 w-full cursor-pointer" [ngClass]="{'!py-[0.2rem]' : this.isOpened}">
                            <button class="flex items-center justify-center rounded-md hover:bg-pst-blue-100 transition-all duration-300" 
                                    [ngClass]="{'bg-pst-blue-100': this.checkIfUrlMatches(item.url), '!justify-start py-[0.5rem] pl-[0.2rem]' : this.isOpened}">
                                <mat-icon class="scale-75" svgIcon="{{ item.icon }}"></mat-icon>
                                @if (this.isOpened) {
                                    <a @appear class="hover:!text-white ml-2 !text-start" >{{ item.title }}</a>
                                }
                            </button>
                        </section>
                    }
                }
            }
            

            <mat-divider class="w-full !border-t-pst-blue-100 my-1"></mat-divider>
        }

        <span class="flex-1"></span>

        @for (icon of footerSectionIcons; track $index) {
            <section class="w-full flex justify-center items-center">
                <button [routerLink]="icon.url" class="px-2 py-1 text-pst-blue-10 cursor-pointer flex items-center border-pst-blue-100 mb-2" [ngClass]="{'mr-2 border-solid border-[1px] rounded-md' : this.isOpened}" (click)="this.closeSession()">
                    <mat-icon class="scale-75" svgIcon="{{ icon.icon }}"></mat-icon>
                    @if (this.isOpened) {
                        <a @appear class="hover:!text-white ml-2" >{{ icon.title }}</a>
                    }
                </button>
            </section>
        }
    }

    <app-notifications (seenNotifications)="decreaseSeenNotifications($event)" [isMobileVersion]="this.isMobileVersion"></app-notifications>
</section>