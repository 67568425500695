import { Injectable } from '@angular/core';
import { FormResponseOfflineData } from '../../models/tourReportForm/formResponseOfflineData';
import { FieldOption } from '../../models/fieldOption.model';
import { FormField } from '../../models/formField.model';
import { TourReportStatusService } from '../tourReports/tour-report-status.service';
import { NotifyToasterService } from '../toaster/notify-toaster.service';

@Injectable({
  providedIn: 'root'
})
export class TourAnswerValidationService {

  constructor(private readonly tourStatusService: TourReportStatusService, private readonly notifyUserService: NotifyToasterService) { }

  isAnyOptionWithoutAction(form: FormResponseOfflineData, formFields: FormField[]): boolean {
    let isValidForm = true;
    form.tourReportFields.forEach((field) => {
      field.tourReportFieldOptions.forEach((fieldOption) => {
        let fieldOptionFromForm: FieldOption = formFields.find((formField) => formField.id === field.formFieldId)?.fieldOptions.find((option) => option.id === fieldOption.fieldOptionId);
        let formField: FormField = formFields.find((formField) => formField.id === field.formFieldId);
        if(fieldOptionFromForm && (fieldOptionFromForm.mandatoryAction || fieldOptionFromForm.requireAction) && field.tourReportActions.length === 0) {
          isValidForm = false;
          this.notifyUserService.showError('Debe seleccionar una acción en el atributo ' + formField?.fieldText);
        }
      });
    });
    return isValidForm;
  }

  isAnyOptionSelected(form: FormResponseOfflineData): boolean {
    let result: boolean =  form.tourReportFields.every((field) => field.tourReportFieldOptions.length === 0);
    if(result)
      this.notifyUserService.showError('Debe seleccionar al menos una opción en todo el formulario');
    return !result;
  }

  getNewTourReportStatus(formResponse: FormResponseOfflineData, formFields: FormField[]): number {
    let isEveryAtributeAnswered: boolean = formFields.every((formField) => formResponse.tourReportFields.find((responseField) => responseField.formFieldId == formField.id));
    if (!isEveryAtributeAnswered)
      return this.tourStatusService.getInProcessTourReportStateId();
    else {
      let isAnyActionSelected: boolean = formResponse.tourReportFields.some((field) => field.tourReportFieldOptions.length > 0);
      if (isAnyActionSelected)
        return this.tourStatusService.getPendingActionsTourReportStateId();
      else {
        return this.tourStatusService.getFinishedTourReportStateId();
      }
    } 
  }
}
