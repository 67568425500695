<section class="flex md:h-full flex-col items-center bg-pst-blue-100 relative transition-all ease-linear z-50" 
    [ngClass]="{'md:w-[250px] h-full': this.isOpened, 'md:w-[150px] h-auto md:!h-full': !this.isOpened}">
    
    <header class="flex justify-start items-center h-[46px] w-full text-pst-blue-10">
        <button mat-icon-button color="primary" (click)="this.toggleSidebar()" class="text-white">
            <mat-icon svgIcon="navbarButton">
            </mat-icon>
        </button>
    </header>

    @if (this.isOpened || !this.isMobileVersion) {
        <section [routerLink]="['/tourType']" class="p-2 text-pst-blue-10 w-full hover:bg-pst-blue-75 cursor-pointer">
            <a class="hover:!text-pst-blue-100" >Tipos de Recorridas</a>
        </section>

        <section [routerLink]="['/form']" class="p-2 text-pst-blue-10 w-full hover:bg-pst-blue-75 cursor-pointer">
            <a class="hover:!text-pst-blue-100" >Formularios</a>
        </section>
        
        <section [routerLink]="['/action']" routerLinkActive="router-link-active" 
                class="p-2 hover:text-pst-blue-100 text-pst-blue-10 w-full hover:bg-pst-blue-75 cursor-pointer">
            <a class="hover:!text-pst-blue-100">Acciones</a>
        </section>

        <span class="flex-1"></span>
    
        <button class="bg-pst-blue-100 hover:bg-pst-blue-75 text-pst-blue-10 font-medium py-2 px-2 mt-2 3xl:text-2xl 
        transition-all ease-linear w-full text-center" [ngClass]="{'text-center': this.isOpened}" (click)="closeSession()">
            Cerrar Sesión
        </button>
    }
</section>