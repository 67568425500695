export const SPINNER_NAME = 'main_spinner';
export const SPINNER_NAME_PROCESS_SELECTOR = 'process_selector_spinner';
export const SPINNER_NAME_TOUR_TYPES = 'tour_types_spinner';
export const SPINNER_NAME_TOURTYPE_IN_FORMS = 'forms_tourType_spinner';
export const SPINNER_NAME_TOUR_REPORTS = 'tour_reports_spinner';
export const SPINNER_NAME_PROCESSTYPE_IN_FORMS = 'forms_ProcessType_spinner';
export const SPINNER_NAME_IN_FORMS = 'forms_allForm_spinner';
export const SPINNER_NAME_FORMS = 'forms_spinner';
export const SPINNER_NAME_HEADER = 'SpinnerName';
export const SPINNER_NAME_ACTIONS_LIST = 'actions_list_spinner';
export const SPINNER_NAME_EDIT_ACTION = 'edit_action_spinner';

export const GET_USER_CONSTANT = 'currentUser';

export const MOBILE_VERSION_WIDTH = 768;

export const PROCESS_COLUMN_INDEX = 1;
export const ATTRIBUTE_COLUMN_INDEX = 2;
export const TOURTYPE_COLUMN_INDEX = 3;