<ng-template #menu cdkPortal>
    <div class="flex flex-col">
        <div class="flex flex-col text-pst-blue-75 overflow-y-auto !bg-grey-100 rounded-md py-2 px-2 gap-2 menu-container relative"
            @dropdownMenuAnimation cdkMenu (scroll)="onScrollLoadData($event);"
            [class]="{'w-[70vw]': this.isMobileVersion, 'w-[45vw]': !this.isMobileVersion}">
            @for (value of alerts; track $index) {
                <a 
                    [routerLink]="value.tourReportId ? ['/tour/tourResponse', value.tourReportId] : ['/executedActions/complete', value.tourReportActionId]"
                    class="bg-white p-1 px-2 flex border-l-8 rounded-md hover:!bg-pst-blue-10 cursor-pointer relative w-full"
                    [class]="{'border-pst-orange-100': !this.isAlertActionType(value), 'border-pst-blue-100': this.isAlertActionType(value)}"
                    (click)="this.closeMenu()"
                >
                    <div class="flex flex-col justify-start flex-1 text-start">
                        <h3 class="text-pst-blue-100">{{value.action ?? value.tourType}}</h3>
                        <span class="text-pst-blue-75 text-xs">{{this.isAlertActionType(value) ? value.tourType : ''}}</span>
                        <span>
                            <span class="text-pst-blue-75 text-xs">
                                @for(block of value.blocks; track $index) {
                                    @if(!$last){
                                        {{block.name}},
                                    } @else {
                                        {{block.name}}
                                    }
                                } @empty {
                                    Sin bloques
                                }
                            </span>
                        </span>
                        <span class="text-pst-blue-100 text-xs font-bold">{{value.deadline ?? ''}}</span>
                    </div>
                    <div class="flex justify-end">
                        <span class="text-pst-orange-100 !text-xs">{{value.creationTime | date:'d MMM'}}</span>
                    </div>
    
                    @if (!value.seenByUser) {
                        <div class="absolute bottom-1 right-1 rounded-full bg-pst-blue-100 w-2 h-2"></div>
                    }
                </a>
            }
            @if (this.isLoading) {
                <div @appear @disappearFaster [class]="{'!justify-center' : !this.isMobileVersion}" class="flex justify-around items-center text-pst-blue-75 !text-base w-full">
                    Cargando Notificaciones <mat-spinner [class]="{'ml-4' : !this.isMobileVersion}" class="!h-5 !w-5"></mat-spinner>
                </div>
            }
        </div>
    </div>
</ng-template>