import { TourReportField } from "./tourReportField.model";

export class FormResponseOfflineData {
    id: number; // tourReportId to know which tour report this form response belongs to
    formCompletedDate: Date;
    tourReportFields: TourReportField[];
    combinedToursIds?: number[];

    constructor() {
        this.id = 0;
        this.formCompletedDate = new Date();
        this.tourReportFields = [new TourReportField()];
        this.combinedToursIds = [];
    }
}