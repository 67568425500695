import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OnlineOrOfflineUserService } from 'src/app/core/services/offlineAuth/online-or-offline-user.service';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { appearAnimation } from 'src/app/core/animations/appear';
import { disappear } from 'src/app/core/animations/disappearFadeOut';
import { MOBILE_VERSION_WIDTH } from 'src/app/core/constants/constants';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule, MatButtonModule, MatIconModule, MatTooltipModule, CommonModule, MatDividerModule],
  templateUrl: './sidebar.component.html',
  animations: [appearAnimation, disappear]
})
export class SidebarComponent implements OnInit {
  constructor(private readonly userService: OnlineOrOfflineUserService) {}

  isOpened: boolean = false;
  windowWidth = window.innerWidth;
  isMobileVersion: boolean = this.getIsMobileVersion();

  firstSectionIcons: any[] = [
    {
      title: 'Home',
      icon: 'home',
      url: '/home'
    },
    {
      title: 'Notificaciones',
      icon: 'notification',
      url: '/notifications'
    }
  ]

  secondSectionIcons: any[] = [
    {
      title: 'Lista de Recorridas',
      icon: 'files-folder',
      url: '/tour'
    },
    {
      title: 'Lista de Acciones',
      icon: 'files-folder-checklist',
      url: '/icon'
    }
  ]

  thirdSectionIcons: any[] = [
    {
      title: 'Tipos de Recorrida',
      icon: 'map-navigation',
      url: '/tourType'
    },
    {
      title: 'Formulario de recorrida',
      icon: 'communication',
      url: '/form'
    },
    {
      title: 'Configuración de Acciones',
      icon: 'business',
      url: '/action'
    },
    {
      title: 'Configuración General',
      icon: 'setting',
      url: '/setting-management'
    }
  ]
  footerSectionIcons: any[] = [
    {
      title: 'Cerrar Sesión',
      icon: 'close-session',
    },
  ]

  sections: any[] = [
    this.firstSectionIcons,
    this.secondSectionIcons,
    this.thirdSectionIcons
  ]

  ngOnInit(): void {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      this.isMobileVersion = this.getIsMobileVersion();
    };
  }

  async closeSession() {
    await this.userService.logout();
  }

  toggleSidebar() {
    this.isOpened = !this.isOpened;
  }

  getIsMobileVersion() {
    return this.windowWidth < MOBILE_VERSION_WIDTH;
  }

  checkIfUrlMatches(url: string) {
    return window.location.pathname === url;
  }
}
