import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsManagerService {
  
  private readonly subscriptions: Subscription[] = [];

  constructor() { }

  addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  unsubscribe() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
