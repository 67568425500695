import { Injectable } from '@angular/core';
import { TourReport } from '../../models/tourReport.model';
import Dexie from 'dexie';
import { AppDatabaseService } from '../indexedDb/app-database.service';
import { Observable } from 'rxjs';
import { PaginationResponse } from '../../models/pagination/pagination-response.model';
import { OfflineAuthService } from '../offlineAuth/offline-auth.service';
import { ProcessTourUsers } from '../../models/processTourUsers.model';
import { FormResponseOfflineData } from '../../models/tourReportForm/formResponseOfflineData';
import { TourAnswerValidationService } from '../tourAnswer/tour-answer-validation.service';
import { Form } from '../../models/form.model';
import { TourReportStatusService } from './tour-report-status.service';

@Injectable({
  providedIn: 'root'
})
export class TourReportCrudService {
  dbSet: Dexie.Table<TourReport, number>;

  constructor(
    private readonly database: AppDatabaseService,
    private readonly userService: OfflineAuthService,
    private readonly tourStatusService: TourReportStatusService,
    private readonly tourAnswerValidation: TourAnswerValidationService,
  ) {
    this.dbSet = this.database.tourReport;
  }

  addAsync(item: TourReport): Observable<TourReport> {
    return new Observable<TourReport>((observer) => {
      this.dbSet.add(item).then((result) => {
        observer.next(item);
        observer.complete();
      }).catch((error) => {
        observer.error(error);
      });
    });
  }

  addOrEditAsync(item: TourReport): Promise<number> {
    return this.dbSet.put(item);
  }

  getAllAsync(
    pageIndex: number, 
    pageSize: number, 
    tourStateIds: number[],
    establishmentIds: number[],
    processId: number, 
    currentUserTourOwner: boolean, 
    noUserAssigned: boolean,
    userName: string) : Observable<PaginationResponse<TourReport>> {
      return new Observable<PaginationResponse<TourReport>>((observer) => {
        this.dbSet
          .filter((report) => tourStateIds.includes(report.currentStatusId))
          .filter((report) => processId ? report.tourType?.processId == processId : true)
          .filter((report) => userName ? report.tourUser?.name.includes(userName) : true)
          .filter((report) => noUserAssigned ? report.tourUser == null : true)
          .filter((report) => establishmentIds.length > 0 ? this.isReportInEstablishmentsIds(report,  establishmentIds) : true)
          .offset(pageIndex * pageSize).limit(pageSize).toArray()
          .then(async (result) => {
            let currentUserName = (await this.userService.getCurrentUser()).username.replace('.', ' ');
            if (currentUserTourOwner) {
              result = result.filter((report) => currentUserTourOwner ? report.tourUser?.name.toLowerCase() == currentUserName.toLowerCase() : true);
            }
            const response: PaginationResponse<TourReport> = {
              items: result,
              totalCount: await this.dbSet
              .filter((report) => tourStateIds.includes(report.currentStatusId))
              .filter((report) => processId ? report.tourType?.processId == processId : true)
              .filter((report) => currentUserTourOwner ? report.tourUser?.name.toLowerCase() == currentUserName.toLowerCase() : true)
              .filter((report) => userName ? report.tourUser?.name.includes(userName) : true)
              .filter((report) => noUserAssigned ? report.tourUser == null : true)
              .filter((report) => establishmentIds.length > 0 ? this.isReportInEstablishmentsIds(report,  establishmentIds) : true)
              .count()
            };
            observer.next(response);
            observer.complete();
          }).catch((error) => {
            observer.error(error);
          });
      });
  }

  private isReportInEstablishmentsIds(report: TourReport, establishmentIds: number[]) {
    return report.establishments.some(establishment => establishmentIds.includes(establishment.id));
  }

  getTourReportById(tourReportId: number): Promise<TourReport> {
    return this.dbSet.get(tourReportId);
  }

  updateTourReportStatus(tourReportId: number, newTourReportStatus: number) {
    return this.dbSet.get(tourReportId).then((tourReport) => {
      tourReport.currentStatusId = newTourReportStatus;
      tourReport.currentStatus = this.tourStatusService.getTourStatusText(newTourReportStatus); 
      this.dbSet.put(tourReport);
    });
  }

  delete(tourReportId: number) {
    this.dbSet.delete(tourReportId);
  }

  addTourUsers(processTourUsers: ProcessTourUsers[], id: number) {
    this.dbSet.get(id).then((tourReport) => {
      tourReport.processTourUsers = processTourUsers;
      this.dbSet.put(tourReport);
    });
  }

  combineTourReports(tourReportsToCombineIds: number[]): Promise<TourReport> {
    return this.dbSet.bulkGet(tourReportsToCombineIds).then(async (tourReports) => {
      let referenceTourReport: TourReport = tourReports[0];
      let blocksIds: number[] = referenceTourReport.blocks.map(block => block.id);
      tourReports.forEach(element => {
        if(referenceTourReport.id != element.id) {
          element.blocks.forEach(block => {
            if(!blocksIds.includes(block.id))
              referenceTourReport.blocks.push(block);
          });
        }
      });

      referenceTourReport.combinedTourReports = tourReports;
      await this.dbSet.put(referenceTourReport);

      tourReports.forEach(element => {
        if(referenceTourReport.id != element.id) {
          this.dbSet.delete(element.id);
        }
      });

      return referenceTourReport;
    });
  }

  getCombinedTourReports(): Promise<TourReport[]> {
    return this.dbSet.where('combinedTourReports').notEqual(null).toArray();
  }

  restoreCombinedTourReports(data: FormResponseOfflineData, form: Form) {
    return this.dbSet.get(data.id).then((tourReports: TourReport) => {
      if(!tourReports) return;
      tourReports.combinedTourReports?.forEach(async (element: TourReport) => {
        this.dbSet.put(element);
        let newTourReportStatusId: number = this.tourAnswerValidation.getNewTourReportStatus(data, form.formFields);
        this.updateTourReportStatus(element.id, newTourReportStatusId);
      });
    });
  }
}