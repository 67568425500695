import { Form } from "../form.model";
import { TourReport } from "../tourReport.model";
import { TourReportStatus } from "../tourReportStatus.model";
import { User } from "../user.model";

const form = new Form();
const tourReport = new TourReport();
const tourReportStatus = new TourReportStatus();
const userInstance = new User();

// Define a generic function to generate columns with a constraint
function generateColumns<T extends Record<string, any>>(instance: T): string {
  return (Object.keys(instance) as (keyof T)[]).join(',');
}

export const DBStores = {
  form: {
    tableName: 'Form',
    columns: generateColumns(form),
  },
  tourReport: {
    tableName: 'TourReport',
    columns: generateColumns(tourReport),
  },
  tourReportStatus: {
    tableName: 'TourReportStatus',
    columns: generateColumns(tourReportStatus),
  },
  user: {
    tableName: 'User',
    columns: generateColumns(userInstance),
  }
};