<section class="h-screen w-screen bg-neutral-50 flex justify-center items-center p-3">
    <form [formGroup]="loginForm" action="" class="rounded-md shadow-lg p-3 3xl:pl-4 min-w-[42%]" (submit)="login()">
        <section class="flex w-full justify-between">
            <h1 class="mb-3 text-xl 2xl:text-4xl text-neutral-950">Iniciar Sesión</h1>
            @if (this.isUserOnline) {
                <button type="button" class="bg-pst-blue-100 hover:bg-pst-blue-75 text-white font-medium h-[30px] px-[0.5rem] ml-2
                    rounded 2xl:text-lg 3xl:text-2xl transition-all ease-linear" @appear @disappear (click)="navigateToLoginOnline()">
                    Login Online
                </button>
            }
        </section>
        
        <section>
            <h2 class="text-base 3xl:text-4xl">Usuario</h2>
            <input formControlName="email" type="text" placeholder="Usuario" autocomplete="email"
                class="!border-0 w-full px-2 py-2 !text-base focus:outline-none rounded-md mb-3 !bg-pst-blue-10 !text-pst-blue-100 3xl:text-3xl 3xl:placeholder:text-lg"/>
            
            <h2 class="text-base 3xl:text-4xl">Contraseña</h2>
            <input formControlName="password" type="password" placeholder="Contraseña" autocomplete="current-password"
                class="!border-0 w-full px-2 py-2 !text-base focus:outline-none rounded-md mb-4 !bg-pst-blue-10 !text-pst-blue-100 3xl:text-3xl 3xl:placeholder:text-lg"/>
        </section>

        <section class="flex">
            <input type="checkbox" class="mr-2 !bg-pst-blue-100 cursor-pointer !border-0 !outline-0" formControlName="rememberMe">
            <span class="text-bold">Recuérdame</span>
        </section>

        <button class="bg-pst-blue-100 hover:bg-pst-blue-75 text-white font-medium py-2 px-4 rounded w-full mt-2 2xl:text-lg 3xl:text-2xl transition-all ease-linear">
            Iniciar Sesión
        </button>
    </form>
</section>