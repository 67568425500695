import { TourType } from "./tourType.model";
import { TourReportStatus } from "./tourReportStatus.model";
import { Block } from "./block.model";
import { TourUser } from "./tourUser.model";
import { Establishment } from "./establishment.model";
import { ProcessTourUsers } from "./processTourUsers.model";

export class TourReport {
    id?: number;
    tenantId?: number;
    tourTypeId: number;
    tourUserId?: number;
    currentStatusId?: number;
    lastUpdatedTime : Date;
    currentStatus: string;
    pendingActions: number;
    tourType?: TourType;
    tourUser?: TourUser
    tourReportStatuses?: TourReportStatus[];
    blocks: Block[];
    establishments: Establishment[];
    processTourUsers: ProcessTourUsers[]; //Used in IndexedDb Only
    combinedTourReports: TourReport[];  //Used in IndexedDb Only

    constructor() {
        this.id = 0;
        this.tenantId = 0;
        this.tourTypeId = 0;
        this.currentStatusId = 0;
        this.lastUpdatedTime = new Date();
        this.currentStatus = '';
        this.pendingActions = 0;
        
        this.tourReportStatuses = [];
        this.blocks = [];
        this.establishments = [];
        this.processTourUsers = [];
        this.combinedTourReports = [];
    }
}