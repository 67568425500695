import { TourType } from "./tourType.model";
import { TourReportStatus } from "./tourReportStatus.model";
import { Block } from "./block.model";
import { TourUser } from "./tourUser.model";

export class TourReport {
    id?: number;
    tenantId?: number;
    tourTypeId: number;

    lastUpdatedTime : Date;
    currentStatus: string;
    tourType?: TourType;
    tourUser?: TourUser
    tourReportStatuses?: TourReportStatus[];
    blocks: Block[];

    constructor() {
        this.id = 0;
        this.tenantId = 0;
        this.tourTypeId = 0;

        this.lastUpdatedTime = new Date();
        this.currentStatus = '';
        this.tourType = undefined;
        this.tourReportStatuses = [];
    }
}