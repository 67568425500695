import { ConnectedPosition, Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { dropdownMenuAnimation } from 'src/app/core/animations/dropdownMenuAnimation';
import { Alert } from 'src/app/core/models/alert.model';
import { AlertsTypeService } from 'src/app/core/services/alerts/alerts-type.service';
import { HttpAlertService } from 'src/app/core/services/http/http-alert.service';
import { SubscriptionsManagerService } from 'src/app/core/services/subscriptions/subscriptions-manager.service';
import { appearAnimation } from 'src/app/core/animations/appear';
import { disappearFaster } from 'src/app/core/animations/disappearFaster';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
  animations: [dropdownMenuAnimation, appearAnimation, disappearFaster],
  standalone: true,
  imports: [MatIconModule, MatPaginatorModule, MatMenuModule, OverlayModule, PortalModule, DatePipe, RouterModule, MatProgressSpinnerModule]
})
export class NotificationsComponent implements OnInit, OnDestroy {
  // Used for subscription cleanup
  private readonly destroy$ = new Subject<void>();

  @Output() seenNotifications: EventEmitter<number> = new EventEmitter<number>();
  @Input() isMobileVersion: boolean = false;
  @ViewChild(CdkPortal) menuContent!: CdkPortal;
  alerts: Alert[] = [];
  private parentElement: ElementRef;
  overlayRef!: OverlayRef;
  pageIndex: number = 0;
  pageSize: number = 10;
  isLoading: boolean = false;
  previousScrollTop: number;
  areThereNotificationsLeft: boolean = true;
  isMenuOpened: boolean = false;
  spinnerName: string = 'notifications';

  constructor(
    private readonly httpAlertService: HttpAlertService,
    private readonly overlay: Overlay,
    private readonly subscriptionsManagerService: SubscriptionsManagerService,
    private readonly alertTypeService: AlertsTypeService
  ) { }

  ngOnInit(): void {
    this.getAlerts();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onScrollLoadData(event: any): void {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;

    if (scrollHeight - (scrollTop + offsetHeight) < 25 && !this.isLoading && this.previousScrollTop < scrollTop) {
      this.pageIndex++;
      this.getAlerts();
    }

    this.previousScrollTop = scrollTop;
  }

  getAlerts() {
    if (this.areThereNotificationsLeft) {
      this.isLoading = true;
      this.httpAlertService.getAlerts(this.pageIndex, this.pageSize, this.spinnerName)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: response => this.handleAlertResponse(response),
          complete: () => {
            this.isLoading = false;
          }
        });
    }
  }

  handleAlertResponse(response: Alert[]) {
    if (response.length == 0)
      this.areThereNotificationsLeft = false;

    response.forEach(alert => {
      //delete repeated blocks
      alert.blocks = alert.blocks.filter((block, index, self) =>
        index === self.findIndex((t) => (
          t.id === block.id
        ))
      );
      this.alerts.push(alert);
    });

    if (this.isMenuOpened)
      this.sendSeenNotificationsEvent(response);
  }

  closeMenu(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    this.isMenuOpened = false;
    this.markAlertsAsSeen();
  }

  public openMenu() {
    this.createOverlay();
    this.overlayRef.attach(this.menuContent);
    this.isMenuOpened = true;
    this.sendSeenNotificationsEvent(this.alerts);
  }

  public setParentElement(parentElement: ElementRef) {
    this.parentElement = parentElement;
  }

  createOverlay() {
    let largeScreenPosition: ConnectedPosition[] = [{
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    }];

    let smallScreenPosition: ConnectedPosition[] = [{
      originX: 'start',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    }];
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.parentElement)
      .withPositions(this.isMobileVersion ? smallScreenPosition : largeScreenPosition);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      maxHeight: this.isMobileVersion ? '90%' : '74%',
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      panelClass: 'ml-6'
    });

    this.subscriptionsManagerService.addSubscription(
      this.overlayRef.backdropClick().subscribe(() => {
        this.closeMenu();
      })
    );
  }

  isAlertActionType(alert: Alert): boolean {
    return this.alertTypeService.isActionAlert(alert);
  }

  sendSeenNotificationsEvent(alerts: Alert[]): void {
    this.seenNotifications.emit(alerts.filter(alert => !alert.seenByUser).length);
  }

  markAlertsAsSeen(): void {
    this.alerts.forEach(alert => {
      alert.seenByUser = true;
    });
  }
}
