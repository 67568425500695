<section class="flex md:h-full flex-col items-center !bg-pst-black-100 relative transition-all ease-linear z-50" 
    [ngClass]="{'md:w-[200px] h-full': this.isOpened, 'md:w-[50px] h-auto md:!h-full': !this.isOpened}">
    
    <header class="flex justify-start items-center h-[46px] w-full text-pst-blue-10 mb-1">
        <button mat-icon-button color="primary" (click)="this.toggleSidebar()" class="text-white">
            <mat-icon svgIcon="logo">
            </mat-icon>
        </button>
        @if (this.isOpened) {
            <h1 class="text-grey-100 ml-1 font-semibold text-[18px] mt-[0.5rem]">Forest Planner</h1>
        }
    </header>

    @if (this.isOpened || !this.isMobileVersion) {
        @for (section of sections; track $index) {
            @for (icon of section; track $index) {
                <section [routerLink]="icon.url" class="px-2 py-[0.75rem] text-pst-blue-10 w-full  cursor-pointer" [ngClass]="{'!py-[0.2rem]' : this.isOpened}">
                    <div class="flex items-center justify-center rounded-md hover:bg-pst-blue-100 transition-all duration-300" 
                            [ngClass]="{'bg-pst-blue-100': this.checkIfUrlMatches(icon.url), '!justify-start py-[0.5rem] pl-[0.2rem]' : this.isOpened}">
                        <mat-icon class="scale-75" svgIcon="{{ icon.icon }}"></mat-icon>
                        @if (this.isOpened) {
                            <a @appear class="hover:!text-white ml-2" >{{ icon.title }}</a>
                        }
                    </div>
                </section>
            }
    
            <mat-divider class="w-full !border-t-pst-blue-100 my-1"></mat-divider>
        }

        <span class="flex-1"></span>

        @for (icon of footerSectionIcons; track $index) {
            <section class="w-full flex justify-center items-center">
                <button [routerLink]="icon.url" class="px-2 py-1 text-pst-blue-10 cursor-pointer flex items-center border-pst-blue-100 mb-2" [ngClass]="{'mr-2 border-solid border-[1px] rounded-md' : this.isOpened}" (click)="this.closeSession()">
                    <mat-icon class="scale-75" svgIcon="{{ icon.icon }}"></mat-icon>
                    @if (this.isOpened) {
                        <a @appear class="hover:!text-white ml-2" >{{ icon.title }}</a>
                    }
                </button>
            </section>
        }
    }
</section>