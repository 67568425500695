import { Environment } from '@abp/ng.core';

const baseUrl = 'https://presupply-tour-dev.upmuruguay.net';

const oAuthConfig = {
  issuer: 'https://presupply-tour-backend-dev.upmuruguay.net/',
  redirectUri: baseUrl,
  clientId: 'PreSupplyTour_App',
  responseType: 'code',
  scope: 'offline_access PreSupplyTour',
  requireHttps: true,
  postLogoutRedirectUri: 'https://login.microsoftonline.com/9eab37f0-91c6-47e3-9c00-fe8544bd272e/oauth2/v2.0/logout?post_logout_redirect_uri=https://presupply-tour-dev.upmuruguay.net/',
};

export const environment = {
  production: true,
  application: {
    baseUrl,
    name: 'PreSupplyTour',
  },
  oAuthConfig,
  apis: {
    default: {
      url: 'https://presupply-tour-backend-dev.upmuruguay.net',
      rootNamespace: 'Upm.PreSupplyTour',
    },
    AbpAccountPublic: {
      url: oAuthConfig.issuer,
      rootNamespace: 'AbpAccountPublic',
    },
  },
  applicationInsights: {
    isEnabled: 'true',
    instrumentationKey: 'd269865c-6bbc-480c-80c0-6af5a4265341'
  },
  grafana: {
    isEnabled: 'true',
    url: 'https://faro-collector-prod-sa-east-1.grafana.net/collect/d34a1f346c74809187a15d0cdb8f2419',
  },
  appVersion: '0.1.0',
  environmentName: 'develop'
} as Environment;
