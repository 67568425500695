import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map, Observable } from 'rxjs';
import { PaginationResponse } from '../../models/pagination/pagination-response.model';
import { Form } from '../../models/form.model';
import { FormsCrudService } from '../forms/forms-crud.service';
import { ConnectionService } from '../connection/connection.service';

@Injectable({
  providedIn: 'root'
})
export class HttpFormService {

  constructor(
    private readonly httpService: HttpService, 
    private readonly indexedDbFormsService: FormsCrudService, 
    private readonly connectionService: ConnectionService
  ) { }

  getFormList(pageIndex: number, pageSize: number, spinnerName: string = ''): Observable<PaginationResponse<Form>> {
    if (!this.connectionService.isOnline) {
      return this.indexedDbFormsService.getAll();
    }
    return this.httpService
      .get(`form?SkipCount=${pageIndex * pageSize}&MaxResultCount=${pageSize}`, spinnerName)
      .pipe(
        map((response: PaginationResponse<Form>) => {
          this.indexedDbFormsService.bulkPut(response.items);
          return response;
        })
      );
  }

  createForm(value: Form, spinnerName: string) {    
    return this.httpService.post<Form>('form', value, spinnerName);
  }

  updateForm(value: Form, spinnerName: string) {
    return this.httpService.put<Form>(`form/${value.id}`, value, spinnerName);
  }

  deleteForm(id: number, spinnerName: string) {
    return this.httpService.delete(`form/${id}`, spinnerName);
  }

  getForm(tourReportId: number, allFormSpinnerName: string = ''): Observable<Form> {
    return this.httpService.get<Form>(`form/${tourReportId}`, allFormSpinnerName);
  }
}
