import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner/spinner.service';
import { SPINNER_NAME_HEADER } from '../constants/constants';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const spinnerName: string | null = req.headers.get(SPINNER_NAME_HEADER);

    if (spinnerName) {
      this.spinnerService.show(spinnerName, req.url);
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (spinnerName) {
          this.spinnerService.hide(spinnerName, req.url);
        }
        return throwError(() => err);
      }),
      map<HttpEvent<any>, HttpEvent<any>>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse && spinnerName) {
          this.spinnerService.hide(spinnerName, req.url);
        }
        return evt;
      })
    );
  }
}