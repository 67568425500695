import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { appearAnimation } from 'src/app/core/animations/appear';
import { disappear } from 'src/app/core/animations/disappearFadeOut';
import { ConnectionService } from 'src/app/core/services/connection/connection.service';
import { OfflineAuthService } from 'src/app/core/services/offlineAuth/offline-auth.service';
import { OnlineOrOfflineUserService } from 'src/app/core/services/offlineAuth/online-or-offline-user.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './login.component.html',
  animations: [appearAnimation, disappear]
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    private readonly offlineAuthService: OfflineAuthService, 
    private readonly conectionService: ConnectionService, 
    private readonly onlineOfflineUserService: OnlineOrOfflineUserService) {}

  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    rememberMe: new FormControl(false),
  });

  isUserOnline: boolean = this.conectionService.isOnline();
  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(this.conectionService.getOnlineStatus().subscribe(isOnline => {
      this.isUserOnline = isOnline;
    }));

    this.subscriptions.push(this.conectionService.getOfflineStatus().subscribe(isOffline => {
      this.isUserOnline = isOffline;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async login() {
    await this.offlineAuthService.loginUser(this.loginForm.value.email, this.loginForm.value.password);
  }

  navigateToLoginOnline() {
    this.onlineOfflineUserService.navigateToOnlineLogin();
  }
}
