import { Component } from '@angular/core';
import { SPINNER_NAME } from './core/constants/constants';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen]="true"
    [name]="this.spinnerName"
  >
    <p class="text-md text-white">Cargando...</p>
  </ngx-spinner>
  `,
})
export class AppComponent {
  spinnerName = SPINNER_NAME;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'navbarButton',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/openButton.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'plus-squared',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/plus-squared.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'addIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'editIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/arrow-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'editIconRounded',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit-icon-rounded.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'deleteIconRounded',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete-icon-rounded.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filterIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/filter-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'addIconRounded',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add-icon-rounded.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'closeIconRounded',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/close-icon-rounded.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ascendingIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/ascending-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'descendingIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/descending-icon.svg')
    );
  }
}
