import { TourReport } from "./tourReport.model";
import { TourStatus } from "./tourStatus.model";

export class TourReportStatus {
    tenantId?: number;
    tourReportId: number;
    tourStatusId: number;
    timeStamp: Date;
    tourReport?: TourReport;
    tourStatus?: TourStatus;

    constructor() {
        this.tenantId = 0;
        this.tourReportId = 0;
        this.tourStatusId = 0;
        this.timeStamp = new Date();
        this.tourReport = new TourReport();
        this.tourStatus = new TourStatus();
    }
}