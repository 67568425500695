import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  loadingUrls: Map<string, boolean[]> = new Map<string, boolean[]>();

  constructor(private readonly ngxSpinnerService: NgxSpinnerService) { }

  show(spinnerName: string, url: string) {
    let loadingUrl = this.loadingUrls.get(url);
    if (loadingUrl) {
      loadingUrl.push(true);
      this.loadingUrls.set(url, loadingUrl);
    } else {
      this.loadingUrls.set(url, [true]);
      this.ngxSpinnerService.show(spinnerName);
    }
  }

  hide(spinnerName: string, url: string) {
    this.loadingUrls.get(url).pop();
    if (this.loadingUrls.get(url).length == 0) {
      this.loadingUrls.delete(url);
      this.ngxSpinnerService.hide(spinnerName);
    }
  }
}
