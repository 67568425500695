import { Action } from "./action.model";
import { Block } from "./block.model";
import { Establishment } from "./establishment.model";
import { TourReportActionComment } from "./tourReportActionComment.model";
import { TourReportActionFile } from "./tourReportActionFile.model";
import { TourUser } from "./tourUser.model";

export class TourReportAction {
    id: number;
    numericIndication: number;
    tourUserId?: number;
    actionId: number;
    currentStatus: string;
    currentStatusId: number;
    tourReportId: number;
    tourReportUserId?: number
    isDraft: boolean;
    isLocalModified: boolean;
    fieldComments: string;
    tourUser?: TourUser;
    tourReportUser?: TourUser;
    action: Action;
    tourReportActionComments?: TourReportActionComment[];
    tourReportActionFiles: TourReportActionFile[];
    blocks: Block[];
    establishments: Establishment[];

    constructor() {
        this.id = 0;
        this.numericIndication = 0;
        this.tourUserId = 0;
        this.actionId = 0;
        this.currentStatus = '';
        this.currentStatusId = 0;
        this.tourReportId = 0;
        this.tourReportUserId = 0;
        this.isDraft = false;
        this.isLocalModified = false;
        this.fieldComments = '';
        this.tourUser = new TourUser();
        this.tourReportUser = new TourUser();
        this.action = new Action();
        this.tourReportActionFiles = [];
        this.tourReportActionComments = [];
        this.blocks = [];
        this.establishments = [];
    }
    
    getMainProcess() {
        return this.action.actionProcesses.find((process) => {
            if (process.isMain) {
                return true;
            }
        }).process;
    }
}

