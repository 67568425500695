import { Injectable } from '@angular/core';
import { Form } from '../../models/form.model';
import Dexie from 'dexie';
import { AppDatabaseService } from '../indexedDb/app-database.service';
import { from, map, Observable } from 'rxjs';
import { TourReportCrudService } from '../tourReports/tour-report-crud.service';
import { TourReport } from '../../models/tourReport.model';
import { PaginationResponse } from '../../models/pagination/pagination-response.model';

@Injectable({
  providedIn: 'root'
})
export class FormsCrudService {

  dbSet: Dexie.Table<Form, number>;

  constructor(private readonly database: AppDatabaseService, private readonly tourReportService: TourReportCrudService) {
    this.dbSet = this.database.form;
  }

  async getFormAsync(tourReportId: number): Promise<Form> {
    let tourReport: TourReport = await this.tourReportService.getTourReportById(tourReportId);
    const form = await this.dbSet.where('tourTypeId').equals(tourReport.tourTypeId).last();
    return form;
  }

  bulkPut(form: Form[]) {
    return this.dbSet.bulkPut(form);
  }

  async addOrEditAsync(response: Form) {
    const formsToDelete = this.dbSet.where('tourTypeId').equals(response.tourTypeId);
    formsToDelete.delete();
    return this.dbSet.put(response);
  }

  getAll(): Observable<PaginationResponse<Form>> {
    return from(this.dbSet.toArray()).pipe(
      map(forms => ({
        items: forms,
        totalCount: forms.length
      }))
    );
  }
}
