import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { OnlineOrOfflineUserService } from '../services/offlineAuth/online-or-offline-user.service';

export const notAbpAuthGuard: CanActivateFn = async (route, state) => {
  const authService = inject(OnlineOrOfflineUserService);
  const router = inject(Router);

  let isAuthenticated = await authService.isAuthenticated();

  if (state.url === '/loginOffline') {
    return true;
  }

  if (!isAuthenticated) {
    router.navigateByUrl('/loginOffline');
    return false;
  }

  return true;
};