import { Form } from "../form.model";
import { TourReport } from "../tourReport.model";
import { TourReportStatus } from "../tourReportStatus.model";
import { User } from "../user.model";
import { OfflineDataDateUpdate } from "../offlineDataDateUpdate.model";
import { FormResponseOfflineData } from "../tourReportForm/formResponseOfflineData";
import { Alert } from "../alert.model";
import { TourReportAction } from "../tourReportAction.model";

const form = new Form();
const tourReport = new TourReport();
const tourReportStatus = new TourReportStatus();
const userInstance = new User();
const offlineDataDateUpdateInstance = new OfflineDataDateUpdate();
const formResponseInstace = new FormResponseOfflineData();
const alertInstance = new Alert();
const actionInstance = new TourReportAction();

// Define a generic function to generate columns with a constraint
function generateColumns<T extends Record<string, any>>(instance: T): string {
  return (Object.keys(instance) as (keyof T)[]).join(',');
}

export const DBStores = {
  form: {
    tableName: 'Form',
    columns: generateColumns(form),
  },
  tourReport: {
    tableName: 'TourReport',
    columns: generateColumns(tourReport),
  },
  tourReportStatus: {
    tableName: 'TourReportStatus',
    columns: generateColumns(tourReportStatus),
  },
  user: {
    tableName: 'User',
    columns: generateColumns(userInstance),
  },
  OfflineDataDateUpdates: {
    tableName: 'OfflineDataDateUpdates',
    columns: generateColumns(offlineDataDateUpdateInstance),
  },
  formResponseOffline: {
    tableName: 'formResponseOffline',
    columns: generateColumns(formResponseInstace)
  },
  alert: {
    tableName: 'Alert',
    columns: generateColumns(alertInstance),
  },
  tourReportAction: {
    tableName: 'tourReportAction',
    columns: generateColumns(actionInstance),
  },
  actionFormResponse: {
    tableName: 'actionFormResponse',
    columns: 'actionId,files,fileBytes,reviewComment',
  },
  actionFormReview: {
    tableName: 'actionFormReview',
    columns: 'actionId,result,comment',
  },
  processTourUser: {
    tableName: 'processTourUser',
    columns: '[processId+zoneId+role],tourUsers',
  }
};