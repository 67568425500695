import { Process } from "./process.model";

export class TourUser {
    id?: number;
    tenantId?: number;
    name: string;
    email?: string;
    identityUserId: string;
    processes: Process[];
    processId: number; // used only in index db

    constructor() {
        this.id = 0;
        this.tenantId = null;
        this.name = '';
        this.email = '';
        this.identityUserId = '';
        this.processes = [];
    }
}