import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
import { AppConstants } from '../../consts/app-constants';
import { faro } from '@grafana/faro-web-sdk';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class MonitoringService {
    private appInsights: ApplicationInsights | null = null;

    constructor(private readonly environmentService: EnvironmentService) {
        this.startMonitoring();
    }

    private startMonitoring(): void {
        if (this.environmentService.isApplicationInsightsEnabled()) {
            const instrumentationKey = environment[AppConstants.ApplicationInsightsConfigurationName].instrumentationKey;
    
            this.appInsights = new ApplicationInsights({
                config: {
                instrumentationKey: instrumentationKey,
                enableAutoRouteTracking: true
            }});
    
            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView();
        }
    }
        
    logEvent(name: string, properties?: { [key: string]: any }): void {
        if (this.environmentService.isApplicationInsightsEnabled()) {
            this.appInsights.trackEvent({ name: name }, properties);
        }
        if (this.environmentService.isGrafanaEnabled()) {
            faro.api.pushEvent(name);
        }
    }
    
    logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
        if (this.environmentService.isApplicationInsightsEnabled()) {
            this.appInsights.trackMetric({ name: name, average: average }, properties);
        }
        if (this.environmentService.isGrafanaEnabled()) {
            faro.api.pushMeasurement({
                type: name,
                values: {
                  duration: average,
                },
              }); 
        }
    }
    
    logException(exception: Error, severityLevel?: number): void {
        if (this.environmentService.isApplicationInsightsEnabled()) {
            this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
        }
        if (this.environmentService.isGrafanaEnabled()) {
            faro.api.pushError(exception);
        }
        console.error(exception);
    }
    
    logTrace(message: string, properties?: { [key: string]: any }): void {
        if (this.environmentService.isApplicationInsightsEnabled()) {
            this.appInsights.trackTrace({ message: message }, properties);
        }
        if (this.environmentService.isGrafanaEnabled()) {
            faro.api.pushLog([message]);
        }
    }
}