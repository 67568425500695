import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { DBStores } from '../../models/indexedDb/indexedDb.store.model';
import { TourReport } from '../../models/tourReport.model';
import { TourReportStatus } from '../../models/tourReportStatus.model';
import { User } from '../../models/user.model';
import { Form } from '../../models/form.model';

@Injectable({
  providedIn: 'root',
})
export class AppDatabaseService extends Dexie {
  form!: Dexie.Table<Form, string>;
  tourReport!: Dexie.Table<TourReport, string>;
  tourStatusHistory!: Dexie.Table<TourReportStatus, string>;
  user!: Dexie.Table<User, string>;

  versionNumber: number = 2;
  private dbName: string = 'index-db-app';

  constructor() {
    super('index-db-app');
    this.version(this.versionNumber).stores(this.setTablesSchema());

    this.form = this.table<Form, string>(DBStores.form.tableName);
    this.tourReport = this.table<TourReport, string>(DBStores.tourReport.tableName);
    this.tourStatusHistory = this.table<TourReportStatus, string>(DBStores.tourReportStatus.tableName);
    this.user = this.table<User, string>(DBStores.user.tableName);
  }
  private setTablesSchema() {
    return Object.entries(DBStores).reduce((tables, [key, value]) => {
      tables[value.tableName] = value.columns;
      return tables;
    }, {} as Record<string, string>);
  }

  async clearDB() {
    this.close();
    await this.delete();
    await this.open();
  }
}