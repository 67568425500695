import { Injectable } from '@angular/core';
import { Alert } from '../../models/alert.model';
import { ALERT_ACTION_TYPE } from '../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class AlertsTypeService {

  constructor() { }

  public isActionAlert(alert: Alert): boolean {
    return alert.type == ALERT_ACTION_TYPE;
  }
}
