import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { OnlineOrOfflineUserService } from 'src/app/core/services/offlineAuth/online-or-offline-user.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { appearAnimation } from 'src/app/core/animations/appear';
import { disappear } from 'src/app/core/animations/disappearFadeOut';
import { MOBILE_VERSION_WIDTH } from 'src/app/core/constants/constants';
import { MatDividerModule } from '@angular/material/divider';
import { NotificationsComponent } from '../notifications/notifications.component';
import { HttpAlertService } from 'src/app/core/services/http/http-alert.service';
import { MatBadgeModule } from '@angular/material/badge';
import { SubscriptionsManagerService } from 'src/app/core/services/subscriptions/subscriptions-manager.service';


@Component({
  selector: 'app-sidebar',
  standalone: true,
  styleUrls: ['./sidebar.component.scss'],
  imports: [RouterModule, MatButtonModule, MatIconModule, MatTooltipModule, CommonModule, MatDividerModule, NotificationsComponent, MatBadgeModule],
  templateUrl: './sidebar.component.html',
  animations: [appearAnimation, disappear]
})
export class SidebarComponent implements OnInit, OnDestroy {
  constructor(
    private readonly userService: OnlineOrOfflineUserService,
    private readonly httpAlertService: HttpAlertService,
    private readonly subscriptionsManager: SubscriptionsManagerService,
    private readonly router: Router
  ) {}

  isOpened: boolean = false;
  windowWidth = window.innerWidth;
  isMobileVersion: boolean = this.getIsMobileVersion();
  notificationsCount: number = 0;

  @ViewChild(NotificationsComponent) notificationsComponent!: NotificationsComponent;

  firstSectionIcons: any[] = [
    {
      title: 'Home',
      icon: 'home',
      url: '/home'
    },
    {
      title: 'Notificaciones',
      icon: 'notification',
      url: '',
      openMenu: true
    }
  ]

  secondSectionIcons: any[] = [
    {
      title: 'Lista de Recorridas',
      icon: 'files-folder',
      url: '/tour'
    },
    {
      title: 'Lista de Acciones',
      icon: 'files-folder-checklist',
      url: '/executedActions'
    }
  ]

  thirdSectionIcons: any[] = [
    {
      title: 'Tipos de Recorrida',
      icon: 'map-navigation',
      url: '/tourType',
      children: []
    },
    {
      title: 'Formulario de recorrida',
      icon: 'communication',
      url: '/form',
      children: []
    },
    {
      title: 'Configuración de Acciones',
      icon: 'business',
      url: '/action',
      children: []
    },
    {
      title: 'Configuración General',
      icon: 'setting',
      url: '/setting-management',
      children: []
    },
    {
      title: 'Configuración Autorización',
      icon: 'setting',
      url: '/identity/roles',
      children: [
        {
          title: 'Roles',
          icon: 'setting',
          url: '/identity/roles'
        },
        {
          title: 'Usuarios',
          icon: 'setting',
          url: '/identity/users'
        }
      ]
    }
  ]

  footerSectionIcons: any[] = [
    {
      title: 'Cerrar Sesión',
      icon: 'close-session',
    },
  ]

  sections: any[] = [
    this.firstSectionIcons,
    this.secondSectionIcons,
    this.thirdSectionIcons
  ]

  ngOnInit(): void {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      this.isMobileVersion = this.getIsMobileVersion();
      this.checkSidebarSections();
    };
    this.checkSidebarSections();

    this.getAlertsCount();

    this.subscriptionsManager.addSubscription(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.getAlertsCount();
        }
      })
    );
  }

  getAlertsCount() {
    this.subscriptionsManager.addSubscription(
      this.httpAlertService.getAlertsCount()
      .subscribe({
        next: response => {
          this.notificationsCount = response;
        }
      })
    );
  }

  ngOnDestroy(): void {
      this.subscriptionsManager.unsubscribe()
  }

  checkSidebarSections() {
    if (this.isMobileVersion) {
      this.sections[0] = this.firstSectionIcons.filter(icon => icon.title != 'Notificaciones');
    } else {
      this.sections[0] = this.firstSectionIcons;
    }
  }

  async closeSession() {
    await this.userService.logout();
  }

  toggleSidebar() {
    this.isOpened = !this.isOpened;
  }

  getIsMobileVersion() {
    return this.windowWidth < MOBILE_VERSION_WIDTH;
  }

  checkIfUrlMatches(url: string) {
    return window.location.pathname === url;
  }

  openMenu() {
      this.notificationsComponent.setParentElement(new ElementRef(document.getElementById('alert')));
    
      this.notificationsComponent.openMenu();
  }

  decreaseSeenNotifications(seenNotifications: number) {
    this.notificationsCount -= seenNotifications;
  }
}
