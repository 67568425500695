import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from './monitoring.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
    constructor(private _monitoringService: MonitoringService) {}

    handleError(error: Error): void {
        this._monitoringService.logException(error);
    }
}