import { Block } from "./block.model";

export class Alert {
    id: number;
    action: string;
    blocks: Block[];
    creationTime: Date;
    seenByUser: boolean;
    tourReportActionId: number;
    tourType: string;
    type: string;

    constructor() {
        this.id = 0;
        this.action = '';
        this.blocks = [];
        this.creationTime = new Date();
        this.seenByUser = false;
        this.tourReportActionId = 0;
        this.tourType = '';
        this.type = '';
    }
}