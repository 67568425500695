import { provideAbpCore, withOptions } from '@abp/ng.core';
import { provideFeatureManagementConfig } from '@abp/ng.feature-management';
import { provideAbpOAuth } from '@abp/ng.oauth';
import { provideSettingManagementConfig } from '@abp/ng.setting-management/config';
import { provideAbpThemeShared, withHttpErrorConfig } from '@abp/ng.theme.shared';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideCommercialUiConfig } from '@volo/abp.commercial.ng.ui/config';
import { provideAccountAdminConfig } from '@volo/abp.ng.account/admin/config';
import { provideAccountPublicConfig } from '@volo/abp.ng.account/public/config';
import { provideAuditLoggingConfig } from '@volo/abp.ng.audit-logging/config';
import { provideFileManagementConfig } from '@volo/abp.ng.file-management/config';
import { provideGdprConfig, withCookieConsentOptions } from '@volo/abp.ng.gdpr/config';
import { provideIdentityConfig } from '@volo/abp.ng.identity/config';
import { provideLanguageManagementConfig } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { provideOpeniddictproConfig } from '@volo/abp.ng.openiddictpro/config';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { provideTextTemplateManagementConfig } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { AccountLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/account';
import { SideMenuLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/layouts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { SpinnerInterceptor } from './core/interceptors/spinner.interceptor';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { AppInitializeFaro } from './shared/observability/faro-initializer-service';
import { AppVersionService } from './shared/services/app-version.service';
import { ErrorHandlerService } from './shared/services/error-handler-service';
import { MonitoringService } from './shared/services/monitoring.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SaasConfigModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    AccountLayoutModule.forRoot(),
    MatIconModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxSpinnerModule,
  ],
  providers: [
    MonitoringService,
    ErrorHandlerService,
    APP_ROUTE_PROVIDER,
    {
      provide: APP_INITIALIZER,
      useFactory: (appVersionService: AppVersionService) => () => AppInitializeFaro(appVersionService),
      deps: [AppVersionService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
    provideAbpCore(
      withOptions({
        environment,
        registerLocaleFn: registerLocale(),
      })
    ),
    provideAbpOAuth(),
    provideAbpThemeShared(
      withHttpErrorConfig({
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      })
    ),
    provideAccountAdminConfig(),
    provideAccountPublicConfig(),
    provideAnimationsAsync(),
    provideAuditLoggingConfig(),
    provideCommercialUiConfig(),
    provideFileManagementConfig(),
    provideFeatureManagementConfig(),
    provideGdprConfig(
      withCookieConsentOptions({
        privacyPolicyUrl: 'gdpr-cookie-consent/privacy',
        cookiePolicyUrl: 'gdpr-cookie-consent/cookie',
      })
    ),
    provideIdentityConfig(),
    provideLanguageManagementConfig(),
    provideOpeniddictproConfig(),
    provideSettingManagementConfig(),
    provideTextTemplateManagementConfig(),

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
