import { ActionProcess } from "./actionProcess.model";
import { UnitOfMeasurement } from "./unitOfMeasurement.model";

export class Action {
    id: number;
    name: string;
    requireNumber: boolean;
    numberQuestion: string;
    mainProcessId: number;
    numberUnitOfMeasurementId: number;
    formFieldId: number;
    daysBeforeHarvestToComplete: number;
    includeMailNotification: boolean;
    notificationDaysFrequency: number;
    actionProcesses: ActionProcess[];
    formField: string;
    tourType: string;
    tourTypeId: number;
    mainProcess?: string;

    unitOfMeasurement: UnitOfMeasurement;

    constructor(action?: Action) {
        this.id = action?.id;
        this.name = action?.name;
        this.requireNumber = action?.requireNumber;
        this.numberQuestion = action?.numberQuestion;
        this.mainProcessId = action?.mainProcessId;
        this.numberUnitOfMeasurementId = action?.numberUnitOfMeasurementId;
        this.formFieldId = action?.formFieldId;
        this.daysBeforeHarvestToComplete = action?.daysBeforeHarvestToComplete;
        this.includeMailNotification = action?.includeMailNotification;
        this.notificationDaysFrequency = action?.notificationDaysFrequency;
        this.actionProcesses = action?.actionProcesses;
        this.formField = action?.formField;
        this.tourType = action?.tourType;
        this.tourTypeId = action?.tourTypeId;
        this.unitOfMeasurement = action?.unitOfMeasurement;
    }
}

