import { initializeFaro, getWebInstrumentations } from '@grafana/faro-web-sdk';
import { AppVersionService } from '../services/app-version.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../../consts/app-constants';

export function AppInitializeFaro(appVersionService: AppVersionService) {
  const isGrafanaEnabled = environment[AppConstants.GrafanaConfigurationName].isEnabled;

  if (!isGrafanaEnabled) {
    return;
  }

  const versionNumber = appVersionService.GetAppVersion();
  const environmentName = environment.environmentName;
  const grafanaUrl = environment[AppConstants.GrafanaConfigurationName].url;
  
  initializeFaro({
    url: grafanaUrl,
    app: {
      name: AppConstants.AppName,
      version: versionNumber,
      environment: environmentName,
    },
    instrumentations: [...getWebInstrumentations({ captureConsole: true, captureConsoleDisabledLevels: [] })],
  });
  
}