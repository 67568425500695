import { Injectable } from '@angular/core';
import { OfflineAuthService } from './offline-auth.service';
import { AuthService, ConfigStateService, CurrentUserDto } from '@abp/ng.core';
import { GET_USER_CONSTANT } from '../../constants/constants';
import { User } from '../../models/user.model';
import { ConnectionService } from '../connection/connection.service';


@Injectable({
  providedIn: 'root'
})
export class OnlineOrOfflineUserService {
  //Todas las operaciones que requieran informacion sobre el usuario actual, deben ser realizadas a traves de este servicio
  //Es el encargado de decidir si se obtiene la informacion del usuario actual de la base de datos local o de la base de datos remota

  constructor(private abpAuthService: AuthService, private offlineAuthService: OfflineAuthService, private onlineUserProfileService: ConfigStateService,
    private connectionService: ConnectionService
  ) {
    this.onlineUserProfileService.getOne$(GET_USER_CONSTANT).subscribe((user) => {
      if (this.connectionService.isOnline() && user.email) {
        this.onlineUser = user;
        this.offlineAuthService.authenticateUserFromOnlineLogin(this.convertOnlineUserToOfflineUser(user));
      }
    });
  }

  private onlineUser = this.onlineUserProfileService.getOne(GET_USER_CONSTANT)
  
  public async getCurrentUser(): Promise<User> {
    if (this.abpAuthService.isAuthenticated) {
      return this.convertOnlineUserToOfflineUser(this.onlineUser);
    } else {
      return await this.offlineAuthService.getCurrentUser();
    }
  }

  public getOnlineUser(): CurrentUserDto {
    return this.onlineUser;
  }

  private convertOnlineUserToOfflineUser(user: CurrentUserDto): User {
    let result = new User();
    result.id = user.id;
    result.email = user.email;
    result.username = user.userName;
    result.roles = user.roles;
    result.isAuthenticated = user.isAuthenticated;
    return result;
  }

  public getOnlineUserAsOfflineUser() {
    return this.convertOnlineUserToOfflineUser(this.onlineUser);
  }

  public async isAuthenticated(): Promise<boolean> {
    let user = await this.offlineAuthService.getCurrentUser();
    return !!user || this.abpAuthService.isAuthenticated;
  }

  public navigateToOnlineLogin() {
    this.abpAuthService.navigateToLogin();
  }

  public async logout() {
    await this.offlineAuthService.logout();
    
    if (this.connectionService.isOnline())
      this.abpAuthService.logout();
    
    this.navigateToOfflineLogin();
  }

  public navigateToOfflineLogin() {
    this.offlineAuthService.navigateToLogin();
  }
}

