import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Alert } from '../../models/alert.model';
import { AlertsCrudService } from '../alerts/alerts-crud.service';
import { ConnectionService } from '../connection/connection.service';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpAlertService {
  private readonly BASE_URL = 'notification';

  constructor(
    private readonly http: HttpService,
    private readonly alertIndexedDbService: AlertsCrudService,
    private readonly connectionService: ConnectionService
  ) { }

  getAlerts(pageIndex: number = 0, pageSize: number = 0, spinnerName: string = '',
    currentUserOwner: boolean = true, markAsSeen: boolean = true)
    : Observable<Alert[]> {
    
    if (this.connectionService.isOnline()) {
      const params = new URLSearchParams({
        SkipCount: (pageIndex * pageSize).toString(),
        MaxResultCount: pageSize.toString(),
        CurrentUserOwner: currentUserOwner.toString(),
        MarkAsSeen: markAsSeen.toString()
      });

      const url = `${this.BASE_URL}?${params.toString()}`;
      return this.http.get<Alert[]>(url, spinnerName)
      .pipe(
        tap(alerts => this.alertIndexedDbService.bulkPut(alerts))
      );
    }
      
    return this.alertIndexedDbService.getAll();
  }

  getAlertsCount() {
    if (this.connectionService.isOnline()) {
      return this.http.get<number>(`${this.BASE_URL}/pending-notifications-count`);
    }

    return this.alertIndexedDbService.getUnseenCount();
  }
}
