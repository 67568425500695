import { Injectable } from '@angular/core';
import { fromEvent, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  private onlineEvent = fromEvent(window, 'online');
  private offlineEvent = fromEvent(window, 'offline');

  isOnline(): boolean {
    return navigator.onLine;
  }

  getOnlineStatus(): Observable<boolean> {
    return this.onlineEvent.pipe(map(() => true));
  }

  getOfflineStatus(): Observable<boolean> {
    return this.offlineEvent.pipe(map(() => false));
  }
}
