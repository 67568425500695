import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OnlineOrOfflineUserService } from 'src/app/core/services/offlineAuth/online-or-offline-user.service';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { appearAnimation } from 'src/app/core/animations/appear';
import { disappear } from 'src/app/core/animations/disappearFadeOut';
import { MOBILE_VERSION_WIDTH } from 'src/app/core/constants/constants';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule, MatButtonModule, MatIconModule, MatTooltipModule, CommonModule],
  templateUrl: './sidebar.component.html',
  animations: [appearAnimation, disappear]
})
export class SidebarComponent implements OnInit {
  constructor(private userService: OnlineOrOfflineUserService, ) {}

  isOpened: boolean = false;
  windowWidth = window.innerWidth;
  isMobileVersion: boolean = this.getIsMobileVersion();

  ngOnInit(): void {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      this.isMobileVersion = this.getIsMobileVersion();
    };
  }

  async closeSession() {
    await this.userService.logout();
  }

  toggleSidebar() {
    this.isOpened = !this.isOpened;
  }

  getIsMobileVersion() {
    return this.windowWidth < MOBILE_VERSION_WIDTH;
  }

}
