import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { Observable, of, from, take } from 'rxjs';
import { Alert } from '../../models/alert.model';
import { AppDatabaseService } from '../indexedDb/app-database.service';

@Injectable({
  providedIn: 'root'
})
export class AlertsCrudService {

  dbSet: Dexie.Table<Alert, number>;
  
    constructor(
      private readonly database: AppDatabaseService, 
    ) {
      this.dbSet = this.database.alerts;
    }
  
    async getAlertAsync(id: number): Promise<Observable<Alert>> {
      const alert = await this.dbSet.where('id').equals(id).last();
      return of(alert);
    }
  
    bulkPut(alert: Alert[]) {
      return this.dbSet.bulkPut(alert);
    }
  
    async addOrEditAsync(response: Alert) {
      const alertsToDelete = this.dbSet.where('id').equals(response.id);
      alertsToDelete.delete();
      return this.dbSet.put(response);
    }
  
    getAll(): Observable<Alert[]> {
      return from(this.dbSet.toArray())
      .pipe(
        take(1)
      );
    }

    getUnseenCount() {
      return from(
        this.dbSet.where('seenByUser').equals('false').count()
      );
    }
}
