import { TourUser } from "../tourUser.model";
import { FormStandDto } from "./formStand.model";

export class TourReportAction {
    id: number;
    actionId: number;
    isDraft: boolean;
    numericIndication: number;
    tourReportActionStands: FormStandDto[];
    tourUser?: TourUser;
    tourUserId: number;

    constructor() {
        this.id = 0;
        this.actionId = 0;
        this.isDraft = false;
        this.numericIndication = 0;
        this.tourReportActionStands = [new FormStandDto()];
        this.tourUser = new TourUser();
        this.tourUserId = 0;
    }
}