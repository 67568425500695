import { TourReportFieldFile } from "../tourReportFieldFile.model";
import { FieldOptionForm } from "./fieldOptionForm";
import { TourReportAction } from "./tourReportAction";

export class TourReportField {
    id: number;
    formFieldId: number;
    comment: string;
    tourReportActions: TourReportAction[];
    tourReportFieldOptions: FieldOptionForm[];
    tourReportFieldFiles: TourReportFieldFile[];

    constructor() {
        this.id = 0;
        this.formFieldId = 0;
        this.comment = '';
        this.tourReportActions = [new TourReportAction()];
        this.tourReportFieldOptions = [new FieldOptionForm()];
        this.tourReportFieldFiles = [new TourReportFieldFile()];
    }
}