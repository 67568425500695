import { Component } from '@angular/core';
import { SPINNER_NAME } from './core/constants/constants';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen]="true"
    [name]="this.spinnerName"
  >
    <p class="text-md text-white">Cargando...</p>
  </ngx-spinner>
  `,
})
export class AppComponent {
  spinnerName = SPINNER_NAME;

  icons: any[] = [
    { name: 'navbarButton', url: 'assets/images/navbar/openButton.svg' },
    { name: 'plus-squared', url: 'assets/images/icons/plus-squared.svg' },
    { name: 'addIcon', url: 'assets/images/icons/add-icon.svg' },
    { name: 'editIcon', url: 'assets/images/icons/edit-icon.svg' },
    { name: 'arrow-right', url: 'assets/images/icons/arrow-right.svg' },
    { name: 'editIconRounded', url: 'assets/images/icons/edit-icon-rounded.svg' },
    { name: 'deleteIconRounded', url: 'assets/images/icons/delete-icon-rounded.svg' },
    { name: 'filterIcon', url: 'assets/images/icons/filter-icon.svg' },
    { name: 'addIconRounded', url: 'assets/images/icons/add-icon-rounded.svg' },
    { name: 'closeIconRounded', url: 'assets/images/icons/close-icon-rounded.svg' },
    { name: 'ascendingIcon', url: 'assets/images/icons/ascending-icon.svg' },
    { name: 'descendingIcon', url: 'assets/images/icons/descending-icon.svg' },
    { name: 'home', url: 'assets/images/navbar/home.svg' },
    { name: 'notification', url: 'assets/images/navbar/notification.svg' },
    { name: 'openButton', url: 'assets/images/navbar/openButton.svg' },
    { name: 'squares', url: 'assets/images/navbar/squares.svg' },
    { name: 'communication', url: 'assets/images/navbar/communication.svg' },
    { name: 'business', url: 'assets/images/navbar/business.svg' },
    { name: 'setting', url: 'assets/images/navbar/setting.svg' },
    { name: 'files-folder', url: 'assets/images/navbar/files-folders.svg' },
    { name: 'files-folder-checklist', url: 'assets/images/navbar/files-folders-checklist.svg' },
    { name: 'map-navigation', url: 'assets/images/navbar/map-navigation.svg' },
    { name: 'close-session', url: 'assets/images/navbar/exit-right.svg' },
    { name: 'logo', url: 'assets/images/navbar/logo.svg' }
  ]

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.icons.forEach(icon => {
      const sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url);
      this.matIconRegistry.addSvgIcon(icon.name, sanitizedUrl);
    });
  }
}
