import { ToasterService } from '@abp/ng.theme.shared';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotifyToasterService {

  constructor(private toasterService: ToasterService) {}

  showSuccess(message: string) {
    this.toasterService.success(message);
  }

  showError(message: string) {
    if (message && message != '' && message != 'undefined')
    this.toasterService.error(message);
  }
}
