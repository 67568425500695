import { FieldOption } from "../fieldOption.model";

export class FieldOptionForm {
    fieldOption?: FieldOption;
    fieldOptionId: number;

    constructor() {
        this.fieldOption = undefined;
        this.fieldOptionId = 0;
    }
}